import zxcvbn from 'zxcvbn';

function passwordCheck(pass, button) {
  var validLength = (pass.length >= 8);
  var validChars = (pass.search(/[a-z]/) !== -1) && (pass.search(/[A-Z]/) !== -1);
  var validDigits = (pass.search(/\d/) !== -1);

  validationTrigger(validLength, 0);
  validationTrigger(validChars, 1);
  validationTrigger(validDigits, 2);

  if (validLength && validChars && validDigits) {
    button.attr("disabled", false);
  } else {
    button.attr("disabled", true);
  }
}

function validationTrigger(validationResult, fieldNumber) {
  var passChecklist = document.querySelectorAll('.x-password-checklist li');
  if (validationResult) {
    passChecklist[fieldNumber].classList.add('done');
  } else {
    passChecklist[fieldNumber].classList.remove('done');
  }
}

function passStrengthCheck() {
  var strength = {
    0: 'Bardzo słabe',
    1: 'Słabe',
    2: 'Średnie',
    3: 'Dobre',
    4: 'Silne'
  };

  $('body').on('input', '.x-password-field', function() {
    var pass = $('.x-password-field').val();
    var button = $('.x-form-button');
    var strengthInfo = document.querySelector('.x-strength-info');
    var strengthBar = document.querySelector('.x-strength-level');

    passwordCheck(pass, button);
    var result = zxcvbn(pass);

    if (pass !== "") {
      strengthInfo.innerHTML = strength[result.score];
      strengthInfo.dataset.strength = result.score;
      strengthBar.dataset.strength = result.score;
    } else {
      strengthInfo.innerHTML = '';
      strengthBar.dataset.strength = '';
      strengthInfo.dataset.strength = '';
    }
  });
}

$(document).ready(function() {
  passStrengthCheck();
});
