import Rails from '@rails/ujs';

$(document).ready(function() {
  var closeTwoFactorAuthenticationPopup = function() {
    $('.modal-wrapper').html('');
    $('.modal-wrapper').removeClass('is-visible');
  };

  var popupVisible = function() {
    return $('.x-2fa-confirmation-popup').size() !== 0;
  };

  var handleSubmission = function(input_holder, submittable, mode) {
    var smsCode = '';
    var smsId = '';

    if (mode === 'submit') {
      smsCode = $('.x-2fa-popup-code-field').val();
      smsId = $('.x-2fa-popup-submit').data('sms-id');
    }

    input_holder.find('.x-2fa-form-code-field').val(smsCode);
    input_holder.find('.x-2fa-form-sms-id-field').val(smsId);

    if ((mode === 'submit' && smsCode !== '') || mode === 'resend') {
      closeTwoFactorAuthenticationPopup();
      Rails.fire(submittable[0], 'submit');
      if (mode === 'resend') {
        toastr.success('Nowy kod został wysłany.', null, { "timeOut": 10000, "extendedTimeOut": 10000 });
      }
    }
  };

  var authenticateForm = function(selector, mode) {
    var uuid = $(selector).closest('.x-2fa-confirmation-popup').data('2fa-uuid');

    mode = mode || 'submit';

    var form = $('.x-2fa-form-' + uuid).closest('form');
    handleSubmission(form, form, mode);
  };

  $('body').on('click', '.x-2fa-popup-submit', function(e) {
    var smsCode = $('.x-2fa-popup-code-field').val();
    var smsId = $(this).data('sms-id');
    e.preventDefault();
    authenticateForm(this);
  });

  $('body').on('click', '.x-2fa-resend-code', function(e) {
    e.preventDefault();
    authenticateForm(this, 'resend');
  });

  $('body').on('click', '.x-2fa-popup-close', function(e) {
    closeTwoFactorAuthenticationPopup();
  });

  $('body').on('click', '.modal-wrapper', function(e) {
    if (e.target === this && popupVisible()) {
      closeTwoFactorAuthenticationPopup();
    }
  });

  $('body').on('keypress', '.x-2fa-confirmation-popup', function(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      authenticateForm(this);
    }
  });

  $('body').on('keydown', function(e) {
    if (e.keyCode === 27 && popupVisible()) {
      closeTwoFactorAuthenticationPopup();
    }
  });

  $('body').on('input', '.x-2fa-popup-code-field', function(e) {
    var submitButton = $('.x-2fa-popup-submit');

    if ($(e.target).val() !== "") {
      submitButton.removeClass('disabled');
    } else {
      submitButton.addClass('disabled');
    }
  });
});
