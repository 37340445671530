function googleAuthSigninErrorMessage() {
  $('.x-flash-message').remove();
  $('.x-google-auth-message-box').removeClass('hide');
  $('.x-google-auth-message-field').html('Nieudane logowanie za pomocą konta gmail.');
}

window.onSignIn = function(googleUser) {
  if (googleUser.isSignedIn()) {
    var token = googleUser.getAuthResponse().id_token;
    var url = $('.x-google-auth-data').data('url');

    $.post(url, { token: token });
  } else {
    googleAuthSigninErrorMessage();
  }
}

window.signOut = function(element) {
  if (gapi.auth2) {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      window.location = element.dataset.url;
    });
  } else {
    window.location = element.dataset.url;
  }
}

window.onLoad = function() {
  gapi.load('auth2', function() {
    gapi.auth2.init();
  });
}
