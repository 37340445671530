$(document).ready(function() {
  $('body').on('click', '.signup .arrow--dropdown', function() {
    $(this).siblings('.tooltip').toggleClass('hide');
  });

  $('body').on('click', '.signup .tooltip', function() {
    $(this).addClass('hide');
  });

  $('body').on('click', '.x-expand-tooltip', function() {
    $(this).children('.tooltip').toggleClass('hide');
  });

  $('body').on('dragover', '.x-dropzone', function() {
    $(this).addClass('dropzone--active');
  });

  $('body').on('dragleave', '.x-dropzone-area', function() {
    $('.x-dropzone').removeClass('dropzone--active');
  });

  $('body').on('drop', '.x-dropzone-area', function(e) {
    $('.x-dropzone').addClass('dropzone--loading');
    e.preventDefault();
  });

  $('body').on('click', '.x-edit-data', function(e) {
    $('.modal-wrapper, .overlayer').addClass('is-visible');
    e.preventDefault();
  });

  $('body').on('click', '.x-close', function(e) {
    $('.modal-wrapper, .overlayer').removeClass('is-visible');
    e.preventDefault();
  });

  $('body').on('click', '.x-stats-takeovers', function(e) {
    var that = $(this);
    e.preventDefault();

    $.ajax({
      url: that.data('url'),
      type: 'GET',
      dataType: 'script'
    });
  });

  $('body').on('click', '.x-crop-image-action', function(e) {
    $('.x-crop-image-layer').addClass('is-visible');
    resetCroppieInstance();
    e.preventDefault();
  });

  $('body').on('click', '.x-crop-image-cancel', function(e) {
    $('.x-crop-image-layer').removeClass('is-visible');
    e.preventDefault();
  });

  $('body').on('click', '.x-crop-image-done', function(e) {
    uploadCroppieResult();
  });

  avatarFilenameFromContentType = function(contentType) {
    return("avatar." + contentType.split('/').pop());
  };

  $('body').on('click', '.x-prevent-default', function(e) {
    e.preventDefault();
  })

  $('.x-agree-more').on('click', function(e) {
    $(this).next('span').removeClass('hide');
    $(this).remove();
    e.preventDefault();
  });

  $('body').on('click', '.x-tip', function() {
    $(this).siblings('.x-toggle-tooltip').toggleClass('hide');
    $(this).toggleClass('show-tip');
    return false;
  });
});
