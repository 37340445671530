import Rails from '@rails/ujs';
Rails.start();

import toastr from 'toastr';
window.toastr = toastr;

import "./toastr";
import './google_auth';
import "./common/default";
import "./two_factor_authentications";
import "./layout/password-strength";
